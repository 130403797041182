export default {
    computed: {
        isRunningInWebView() {
            const userAgent = navigator.userAgent.toLowerCase();
            return userAgent.includes('envia webview');
        },
    },
    methods: {
        itsAnExternalDomain(redirect_url) {
            if (redirect_url.startsWith('/')) {
                return false;
            }

            const formatted_url = new URL(redirect_url);
            const current_host = window.location.host;

            return formatted_url.host !== current_host;
        },
        formatWebviewRedirectUrl(redirect_url) {
            if (this.itsAnExternalDomain(redirect_url)) {
                const url_base64 = Buffer.from(redirect_url).toString('base64');
                return `enviaclientapp://webView?path=${url_base64}`;
            }

            const formatted_url = new URL(redirect_url);
            const webview = this.$route.query.webview;

            formatted_url.searchParams.set('webview', webview);

            return formatted_url.toString();
        },
    },
};
