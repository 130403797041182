<template>
    <div class="verify-page">
        <logos-container />
        <h1 class="verify-page__title">{{ $t('Verification Code') }}</h1>
        <p class="verify-page__text">{{ $t('Open the two-step verification app on your mobile device to get your verification code.') }}</p>
        <div>
            <form @submit.prevent="verify()">
                <div class="mb-3">
                    <ui-input
                        id="code"
                        name="code"
                        type="number"
                        placeholder="000000"
                        v-model="token"
                        :label="$t('Code')"
                        :field="v$.token"
                    />
                </div>
                <ui-button :loading="loading" class="w-100 mb-0" type="submit" variant="primary">
                    {{ $t('Validate code') }}
                </ui-button>
            </form>
            <div class="text-left mt-4">
                <b-button :disabled="sendingEmail" variant="link" type="button" class="verify-page__send-code" @click="sendEmail">
                    <span v-if="sendingEmail" class="ui-button__loader fas fa-spinner fa-spin"></span>
                    {{ $t('Send the code to my email') }}
                </b-button>
            </div>
        </div>
    </div>  
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, minLength, helpers } from '@vuelidate/validators';

import UiInput from '@/components/ui/inputs/Input.vue';
import UiButton from '@/components/ui/buttons/Button.vue';
import LogosContainer from '@/components/ui/LogosContainer.vue';

import webViewMixin from '@/plugins/mixin/web-view.mixin';

export default {
    components: { UiInput, UiButton, LogosContainer },
    mixins: [webViewMixin],
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            loading: false,
            sendingEmail: false,
            token: '',
            isCodeFromEmail: false
        };
    },
    validations() {
        return {
            token: {
                required: helpers.withMessage(this.$t('validations.required', { field: this.$t('code') }), required),
                minLength: helpers.withMessage(({ $params }) => this.$t('This field must be a minimum of {min} characters', $params ), minLength(6)),
                maxLength: helpers.withMessage(({ $params }) => this.$t('This field must have a maximum of {max} characters', $params ), maxLength(6)),
                $autoDirty: true,
            },
        };
    },
    methods: {
        async verify() {
            try {
                this.loading = true;

                this.v$.$touch();

                if (this.v$.$invalid) {
                    return;
                }

                const token = `${this.$route.query.email}:${this.token}`;
                const encodedToken = Buffer.from(token).toString('base64');
                const config = { headers: { 'Authorization': `Basic ${encodedToken}` } };

                const payload = {
                    token: ''
                };

                if (this.isCodeFromEmail) {
                    payload.type = 'email';
                }

                const { data } = await this.axiosAccount.post('/api/login/tfa', payload, config);
                let { redirect_url } = data;
                const webview = this.$route.query.webview;

                if (webview) {
                    redirect_url = this.formatWebviewRedirectUrl(redirect_url);
                }

                window.location.replace(redirect_url);
            } catch(error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        async sendEmail() {
            try {
                this.sendingEmail = true;
                this.isCodeFromEmail = true;
                const payload = {
                    "type": "email",
                    "message": "Two Factor Authentication",
                    "action":"email_twofactor",
                    "from": "Accounts Tendencys"
                }
                await this.axiosAccount.post('/api/notifications/tfa', payload);
                this.$toasted.global.successMessage('Email sent successfully');
            } catch(error) {
                this.showError(error);
            } finally {
                this.sendingEmail = false;
            }
        }
    },
};
</script>

<style lang="scss">
.verify-page {

    &__title {
        font-size: 35px;
        font-weight: 600;
    }

    &__text {
        color: #787F84;
        font-size: 16px;
        margin-bottom: 35px;
    }

    &__send-code {
        color: $accounts-secondary !important;
        font-weight: 500 !important;
        margin-bottom: 20px;
        padding: 0 !important;
        text-align: left;
    }
}
</style>
